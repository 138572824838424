import React from "react";
import {SelectFilterButton} from "./SelectFilterButton";
import {useJqlFlag} from "../../hooks/useJqlFlag";
import {useTranslation} from "react-i18next";
import {buttons, searchFilterClick, SearchFiltersInterface} from "./elsearch.domain";
import {usePeopleFlag} from "../../hooks/usePeopleFlag";

const SearchFiltersTop: React.FC<SearchFiltersInterface> = ({
                                                                setSearchIndex,
                                                                setDataType,
                                                                dataType,

                                                            }) => {

    const {t} = useTranslation();
    const jql = useJqlFlag();
    const people = usePeopleFlag();
    let buttonNames = Object.keys(buttons);
    if (!jql) buttonNames = buttonNames.filter((name) => name !== "jql");
    if (!people) buttonNames = buttonNames.filter((name) => name !== "people");

    return (
        <div className="filter-conatiner">
            <div className="looking-for-text">{t("filterPage.filterMenu.lookingFor")}</div>
            <div className="filter-tabs filter-tabs-top">
                {buttonNames.map(buttonName =>
                    <SelectFilterButton
                        {...buttons[buttonName]}
                        key={buttonName}
                        onClick={searchFilterClick(buttons[buttonName].dataType, setDataType, setSearchIndex)}
                        clazz={dataType === buttonName ? "active" : ""}
                    />)}
            </div>
        </div>
    );
};
export default SearchFiltersTop;
