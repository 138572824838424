import {FetchProfileImageResult, useFetchProfileImage} from "./fetch.profile.images";
import {LoadingOr} from "./loadingOr";

type ProfileImageProps = {
    userId: string;
    token?: () => Promise<string>; // Function to fetch the token
    altText?: string; // Optional alt text for the image
};

//Needs to be stable across renders so don't move
const disposeOnUnmount = (res: FetchProfileImageResult) => res.dispose();

function ProfileImageError({error}: { error: string }): React.ReactElement {
    return <div>Custom Error loading profile image: {error}</div>;
    //We will replace with error icon after permission issues resolved. Chat with Hari
}

export function ProfileImage(props: ProfileImageProps) {
    const {altText = "Profile image"} = props;
    const fetchProfileImage = useFetchProfileImage();
    return <LoadingOr input={props} kleisli={fetchProfileImage} onUnmount={disposeOnUnmount} error={ProfileImageError} >
        {({imageUrl}) =>
            <img src={imageUrl} alt={altText}/>}
    </LoadingOr>;
}
