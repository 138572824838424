import ragComponent from "./rag.component";
import showIncidentsOrOrders from "./showIncidentsOrOrders.component";
import { IActionComponentProps } from "./types";
import { quickActions } from "../quick-actions/quickActions.component";

const lookupTableAboutChatbotActions: IActionComponentProps = {
    rag: ragComponent,
    "show-incidents": showIncidentsOrOrders,
    "show-orders": showIncidentsOrOrders,
    "quick-actions": quickActions,
    default: ragComponent,
};

export { lookupTableAboutChatbotActions };
