import {ElSearchAdvanceCard, ElSearchAdvanceCardType} from "../results/ElSearchAdvanceCard";
import {createContext, useContext} from "react";
import {DataType} from "../ElSearchAdvanceHelpers";
import {ElSearchAdvanceCardForPeople} from "../results/ElSearchAdvanceCardForPeople";

export type Components = {
    ElSearchAdvanceCard: ElSearchAdvanceCardType
}
export type ComponentsSource = Components & {
    ElSearchAdvanceCard: ElSearchAdvanceCardType,
    ElSearchAdvanceCardForPeople: ElSearchAdvanceCardType
}
export const defaultComponentSource: ComponentsSource = {
    ElSearchAdvanceCard,
    ElSearchAdvanceCardForPeople
};
export const componentsContext = createContext<ComponentsSource>(defaultComponentSource);

export function useComponents(dataType: DataType): Components {
    const source = useContext(componentsContext);
    if (dataType === "people") return {ElSearchAdvanceCard: source.ElSearchAdvanceCardForPeople};
    return source;
}

