import axios from "axios";
import {defaultApiKeyFn} from "../search/searchContext";
import {EnvironmentConfig} from "../config/EnvironmentConfig";

export const getQuestionData = async (searchTerm: string, indicies: string[], language?: string) => {
    const indiciesString = indicies.join(",");
    const apiKey = defaultApiKeyFn();

    const url = `${EnvironmentConfig.elasticSearchBaseUrl}/${indiciesString}/_search`;

    return axios.post(
        url,
        {
            query: {
                bool: {
                    must: {
                        match_phrase_prefix: {
                            keyfield: searchTerm,
                        },
                    },
                    must_not: [
                        {
                            term: {
                                chunk_type: "original_document",
                            },
                        },
                        {
                            term: {
                                chunk_type: "semantic_enriched",
                            },
                        },
                        {
                            term: {
                                type: "content",
                            },
                        },
                    ],
                },
            },
        },
        {
            headers: {
                Authorization: `ApiKey ${apiKey}`,
            },
        }
    )
        .then((response) => {
            if (!language) return [];
            const filteredHits = response.data.hits.hits.filter((hit: any) => language && hit._source?.t?.question[language]);
            return filteredHits.map((hit: any) => ({title: hit._source.t.question[language]}));
        })
        .catch((error) => {
            console.error("Fetch Error :-S", error);
            return error;
        });
};
