import parseLinks, {
    parsedLinkArray,
} from "../../ai-assist-refactored/utils/parseLinks";
import {
    AiClient,
    OutputAndSource,
    useAiWithRag,
} from "../../ai/ai.rag.client";
import RelatedApps from "./related-apps/RelatedApps";
import "./UnifiedSearch.scss";
import { useEffect, useState } from "react";
import { marked } from "marked";
import { FilterSetters } from "../elsearch.domain";
import { EonUiButton } from "@eon-ui/eon-ui-components-react";
import { useTranslation } from "react-i18next";
import { TSetShowAiAssist } from "../../ai-assist-refactored/AiAssist";
import { ReactComponent as AiIcon } from "../../../assets/image/ai-icon32.svg";
import LoaderComponent from "../../loader-component/LoaderComponent";

const AiChatBotResponse = ({
    aiResponse,
    setShowAiAssist,
}: {
    aiResponse: string[];
    setShowAiAssist: TSetShowAiAssist;
}) => {
    const linksArray = parsedLinkArray(
        aiResponse.length > 0 ? aiResponse[0] : ""
    );
    const { t } = useTranslation();

    return (
        <>
            <div className="role-list">
                {aiResponse.map(
                    (aiResponse, index) =>
                        aiResponse && (
                            <div
                                className="unified-bot-text-format"
                                key={index}
                                dangerouslySetInnerHTML={{
                                    __html: marked.parseInline(
                                        aiResponse
                                    ) as string,
                                }}
                            />
                        )
                )}
                {linksArray && linksArray.length > 0 && (
                    <div className="citation-container">
                        {linksArray.map((link, index) => (
                            <EonUiButton
                                size="small"
                                rank="secondary"
                                text={link.text ?? link.url}
                                onClick={() => window.open(link.url, "")}
                                key={index}
                                className="citation-item"
                            ></EonUiButton>
                        ))}
                    </div>
                )}
            </div>
            <div className="show-more-button">
                <EonUiButton
                    title={t("chatBot.sendbtn")}
                    text={t("chatBot.showMore")}
                    placeholder={""}
                    onClick={() => {
                        setShowAiAssist(true);
                        console.log("Send button clicked");
                    }}
                    size="small"
                    rank="secondary"
                ></EonUiButton>
            </div>
        </>
    );
};

const useSearchAiChatBot = (searchTerm: string) => {
    const ai: AiClient = useAiWithRag();
    const [response, setResponse] = useState<string | null>(null);
    const lang: string =
        localStorage.getItem("i18nextLng") === "de" ? "de" : "en";

    useEffect(() => {
        const fetchResponse = async () => {
            const result: OutputAndSource = await ai.aiClientWithRag(
                [],
                searchTerm,
                lang,
                [
                    "ai-azureblob-test",
                    "actions-prod",
                    "office-buddy-prod",
                    "apps-prod",
                ]
            );
            setResponse(`${parseLinks(result.output)}`);
        };
        if (searchTerm) {
            fetchResponse();
        }
    }, [searchTerm]);

    return response;
};

const UnifiedSearch = ({
    searchTerm,
    setters,
    setShowAiAssist,
}: {
    searchTerm: string;
    setters: FilterSetters;
    setShowAiAssist: TSetShowAiAssist;
}) => {
    const aiResponse = useSearchAiChatBot(searchTerm);

    return (
        <div className="advance-unified-assist">
            {aiResponse ? (
                <div className="chatbot-response">
                    <div className="ai-logo">
                        <AiIcon />
                        <span>AI Assistant</span>
                    </div>
                    <AiChatBotResponse
                        aiResponse={[aiResponse]}
                        setShowAiAssist={setShowAiAssist}
                    />
                </div>
            ) : (
                <div className="no-response-loader">
                    <LoaderComponent isLoading={true} isLinear />
                </div>
            )}
            <RelatedApps searchTerm={searchTerm} setters={setters} />
        </div>
    );
};
export default UnifiedSearch;
