import { useTranslation } from "react-i18next";
import "./EditBooking.scss";
import {
    EonUiButton,
    EonUiDatePicker,
    EonUiDropdown,
    EonUiDropdownOption,
    EonUiInput,
} from "@eon-ui/eon-ui-components-react";
import useRoomBookingStore from "../../../store/roomBookingStore";
import {
    getBookingContainerTemplateApi,
    getTimeLinesApi,
    saveBookingsApi,
    updateBookingApi,
} from "../../../services/roomBooking";
import { useEffect, useState } from "react";
import {
    convertDurationToMilliseconds,
    getFormattedDateTime,
} from "../../../util/date";
import { getAccessToken } from "../../../services/accessToken";
import IconComponent from "../../generic-components/icon/IconComponent";
import { EonUiInputCustomEvent } from "@eon-ui/eon-ui-components";
import { DURATION_OPTIONS } from "../RoomBookingInterface";
import LoaderComponent from "../../loader-component/LoaderComponent";
import TimePicker from "../../time-picker/TimePicker";

type EditBookingProps = {
    callback: () => void;
    action: "edit" | "rebook";
};

const EditBooking = ({ callback, action }: EditBookingProps) => {
    const {
        currentBookingData,
        setEditBookingModal,
        setRebookingModal,
        manageBookingRawData,
        bookAgainRawData,
        userData,
        numberOfParticipantsRebooking,
        setNumberOfParticipantsRebooking
    } = useRoomBookingStore();
    const { t, i18n } = useTranslation("room-booking");
    const [availabilityCode, setAvailabilityCode] = useState<number>(-1);
    const [meetingTopic, setMeetingTopic] = useState<string>("");
    const [dateValue, setDateValue] = useState<string>("");
    const [dateValueChanged, setDateValueChanged] = useState<boolean>(false);
    const [startTime, setStartTime] = useState<string>("");
    const [duration, setDuration] = useState<string>("");
    const [preFillComplete, setPreFillComplete] = useState<boolean>(false);
    const [successMessage, setSuccessMessage] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [updateSuccess, setUpdateSuccess] = useState<boolean>(false);
    const [invalidMeetingTopic, setInvalidMeetingTopic] = useState(false);
    const [invalidNumberOfParticipants, setInvalidNumberOfParticipants] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const date = new Date(currentBookingData.meeting_on);
    const options: Intl.DateTimeFormatOptions = {
        weekday: "long",
        day: "2-digit",
        month: "short",
    };
    const currentTime = new Date().getTime();

    useEffect(() => {
        const meeting = getMeetingDetailsById(currentBookingData.meeting_id);
        setTimeout(() => {
            setMeetingTopic(
                currentBookingData.meeting_desc
            );
            setDateValue(
                preFillDate(meeting.begin.timestamp)
            );
            setDuration(
                preFillDuration(meeting.begin.timestamp, meeting.end.timestamp)
            );
            setStartTime(
                preFillStartTime(meeting.begin.timestamp)
            );
            setNumberOfParticipantsRebooking(
                currentBookingData.meeting_participants
            );
            if (action == "rebook") setPreFillComplete(true);
        }, 0);
    }, []);

    useEffect(() => {
        preFillComplete && checkAvailability();
    }, [startTime, duration]);

    const preFillDuration = (startTime: number, endTime: number): string => {
        const diffInMinutes = (endTime - startTime) / 60;
        return diffInMinutes < 60
            ? diffInMinutes + " Min"
            : diffInMinutes / 60 + " Hr";
    };

    const preFillDate = (startTime: number): string => {
        return action === "edit" ? getFormattedDateTime(startTime * 1000, "Y-m-d") : getFormattedDateTime(currentTime, "Y-m-d");
    };

    const preFillStartTime = (startTime: number): string => {
        const nextTime = Math.ceil(currentTime / (15 * 60 * 1000)) * (15 * 60 * 1000);
        return action === "edit" ? getFormattedDateTime(startTime * 1000, "H:i") : getFormattedDateTime(nextTime, "H:i", false, timezone);
    };

    const formattedMeetingDate = date
        .toLocaleDateString("en-GB", options)
        .replace(/ /g, " ");

    const updateBooking = async (meeting_id: number) => {
        setUpdateSuccess(true);
        setIsLoading(true);
        const actualToken = await getAccessToken();
        const payload = await getUpdateBookingPayload(meeting_id);
        if (!payload) {
            setErrorMessage(t("apiFailDefaultMsg"));
            setUpdateSuccess(false);
            return;
        }
        const begin = new Date(dateValue + " " + startTime).getTime();
        const end =
            new Date(dateValue + " " + startTime).getTime() +
            convertDurationToMilliseconds(duration);
        const format = "Y-m-d H:i:s";

        payload[0].booking_templates[0].begin.timestamp = begin / 1000;
        payload[0].booking_templates[0].begin.datetime = getFormattedDateTime(
            begin,
            format,
            false,
            timezone
        );
        payload[0].booking_templates[0].end.timestamp = end / 1000;
        payload[0].booking_templates[0].end.datetime = getFormattedDateTime(
            end,
            format,
            false,
            timezone
        );
        payload[0].booking_templates[0].initial_begin.timestamp = begin / 1000;
        payload[0].booking_templates[0].initial_begin.datetime =
            getFormattedDateTime(begin, format, false, timezone);
        const { seating_id } = payload[0].booking_templates[0];
        payload[0].booking_templates[0].seating_id = seating_id === 0 ? null : seating_id;
        const finalPayload = {
            include: [],
            booking_container_templates: payload,
            dummytoken: "",
            accesstoken: actualToken || "",
            send_mails: true,
        };

        const newBookingTemplate = {
            begin: {
                timestamp: begin / 1000,
                datetime: getFormattedDateTime(begin, format, false, timezone),
            },
            end: {
                timestamp: end / 1000,
                datetime: getFormattedDateTime(end, format, false, timezone),
            },
            initial_begin: {
                timestamp: begin / 1000,
                datetime: getFormattedDateTime(begin, format, false, timezone),
            },
        };
        finalPayload.booking_container_templates.booking_templates =
            newBookingTemplate;
        try {
            const response = await updateBookingApi(finalPayload);
            if (
                response.status == "success" &&
                response.data.booking_container_results[0].result &&
                !response.data.booking_container_results[0].errors.length
            ) {
                setSuccessMessage(t("updateBookingApiSucessMsg"));
            } else {
                setErrorMessage(t("apiFailDefaultMsg"));
                setUpdateSuccess(false);
                console.log("something went wrong!", response);
            }
        } catch (e) {
            setErrorMessage(t("apiFailDefaultMsg"));
            setUpdateSuccess(false);
            console.log("something went wrong!", e);
        } finally {
            setIsLoading(false);
        }
    };

    const getUpdateBookingPayload = async (meeting_id: number) => {
        const meeting = getMeetingDetailsById(meeting_id);
        const actualToken = await getAccessToken();
        const payload = {
            booking_container_ids: [meeting.booking_container_id],
            include: [],
            accesstoken: actualToken || "",
        };
        try {
            const response = await getBookingContainerTemplateApi(payload);
            if (
                response.status == "success" &&
                response.data.booking_container_templates.length
            ) {
                return response.data.booking_container_templates;
            } else {
                console.log("something went wrong!", response);
            }
        } catch (e) {
            console.log("something went wrong!", e);
        }
    };

    const validateBooking = () => {
        let valid = true;
        if (!meetingTopic) {
            setInvalidMeetingTopic(true);
            valid = false;
        }
        if (!numberOfParticipantsRebooking) {
            setInvalidNumberOfParticipants(true);
            valid = false;
        }
        return valid;
    };

    const saveBooking = async (meeting_id: number) => {

        if (!validateBooking()) {
            return false;
        }

        setUpdateSuccess(true);
        setIsLoading(true);

        const meeting = getMeetingDetailsById(meeting_id);

        const actualToken = await getAccessToken();
        const begin = new Date(dateValue + " " + startTime).getTime();
        const end =
            new Date(dateValue + " " + startTime).getTime() +
            convertDurationToMilliseconds(duration);
        const format = "Y-m-d H:i:s";
        const { user_id, timezone_id } = userData;
        const payload = {
            booking_container_templates: [
                {
                    user_id,
                    terms: [],
                    booking_templates: [
                        {
                            object_id: meeting.object_id,
                            cost: 0,
                            begin: {
                                datetime: getFormattedDateTime(
                                    begin,
                                    format,
                                    false,
                                    timezone
                                ),
                                timezone_id: timezone_id,
                            },
                            end: {
                                datetime: getFormattedDateTime(
                                    end,
                                    format,
                                    false,
                                    timezone
                                ),
                                timezone_id: timezone_id,
                            },
                            topic: meetingTopic,
                            capacity: numberOfParticipantsRebooking,
                            accessories: [],
                        },
                    ],
                },
            ],
            send_mails: true,
            dummytoken: "",
            accesstoken: actualToken || "",
        };

        try {
            const response = await saveBookingsApi(payload);
            if (
                response.status == "success" &&
                response.data.booking_container_results[0].result &&
                !response.data.booking_container_results[0].errors.length
            ) {
                setSuccessMessage(t("rebookingApiSucessMsg"));
            } else {
                setErrorMessage(t("apiFailDefaultMsg"));
                setUpdateSuccess(false);
                console.log("something went wrong!", response);
            }
        } catch (e) {
            setErrorMessage(t("apiFailDefaultMsg"));
            setUpdateSuccess(false);
            console.log("something went wrong!", e);
        } finally {
            setIsLoading(false);
        }
    };

    const getMeetingDetailsById = (meeting_id: number) => {
        if (action === "rebook") {
            return bookAgainRawData.find(
                (obj) => obj.booking_id === meeting_id
            );
        } else if (action === "edit") {
            return manageBookingRawData.find(
                (obj) => obj.booking_id === meeting_id
            );
        }
    };

    const getMeetingDuration = (meeting_id: number) => {
        const meeting = getMeetingDetailsById(meeting_id);
        const diff = (meeting.end.timestamp - meeting.begin.timestamp) / 3600;

        return diff === 1 ? `${diff} Hour` : `${diff} Hours`;
    };

    const checkAvailability = async () => {
        setSuccessMessage("");
        setErrorMessage("");
        setAvailabilityCode(-1);
        setIsLoading(true);
        const actualToken = await getAccessToken();
        const meeting = getMeetingDetailsById(currentBookingData.meeting_id);
        const begin = new Date(dateValue + " " + startTime).getTime();
        const end =
            new Date(dateValue + " " + startTime).getTime() +
            convertDurationToMilliseconds(duration);
        const format = "Y-m-d H:i:s";
        const payload = {
            object_ids: [meeting.object_id],
            timeframes: [
                {
                    begin: {
                        datetime: getFormattedDateTime(
                            begin,
                            format,
                            false,
                            timezone
                        ),
                        timezone_id: meeting.begin.timezone_id,
                    },
                    end: {
                        datetime: getFormattedDateTime(
                            end,
                            format,
                            false,
                            timezone
                        ),
                        timezone_id: meeting.end.timezone_id,
                    },
                },
            ],
            ignore_booking_ids: [meeting.booking_id],
            dummytoken: "",
            accesstoken: actualToken || "",
        };
        try {
            const response = await getTimeLinesApi(payload);
            if (response.status == "success") {
                setAvailabilityCode(response.data[0].availability);
            } else {
                console.log("something went wrong!", response);
            }
        } catch (e) {
            console.log("something went wrong!", e);
        } finally {
            setIsLoading(false);
        }
    };

    const getMaxCapacity = (meeting_id: number) => {
        const meeting = getMeetingDetailsById(meeting_id);
        let maxCapacity = currentBookingData.meeting_participants;
        try {
            maxCapacity = meeting.seatings[0].capacity_max;
        } catch (e) {
            console.log(e);
        }
        return maxCapacity;
    };

    const closePopup = () => {
        if (action === "edit") {
            setEditBookingModal(false);
        } else if (action === "rebook") {
            setRebookingModal(false);
        }
        updateSuccess && callback && callback();
    };

    return (
        <div className="edit-booking-container" data-testid={`editBooking-${action}`}>
            { isLoading && <LoaderComponent isLoading /> }
            { action === "edit" ? (
                <div className="heading">{t("manageBooking.reschedule")}</div>
            ) : (
                <div className="heading">{t("manageBooking.bookAgain")}</div>
            )}
            { availabilityCode !== -1 && (
                <div className="availability-info">
                    { !successMessage && availabilityCode === 0 && (
                        <div className="heading info">
                            <IconComponent
                                className="icon"
                                src={`/icons/tick.svg`}
                            />
                            <div className="text">{t("availability.free")}</div>
                        </div>
                    )}
                    { !successMessage && [1, 2].includes(availabilityCode) && (
                        <>
                            <div className="heading error">
                                <IconComponent
                                    className="icon"
                                    src={`/icons/cross.svg`}
                                />
                                <div className="text">{t("availability.busy")}</div>
                            </div>
                            <div className="hint">{t("availability.busyHint")}</div>
                        </>
                    )}
                    { (successMessage && action === "edit") && (
                        <div className="heading info">
                            <IconComponent
                                className="icon"
                                src={`/icons/success.svg`}
                            />
                            <div className="text">{t("updateBookingApiSucessMsg")}</div>
                        </div>
                    )}
                    { (successMessage && action === "rebook") && (
                        <div className="heading info">
                            <IconComponent
                                className="icon"
                                src={`/icons/success.svg`}
                            />
                            <div className="text">{t("rebookingApiSucessMsg")}</div>
                        </div>
                    )}
                </div>
            )}
            <div className="title">
                { action === "edit" ? currentBookingData.meeting_desc : (
                    <EonUiInput
                        type="text"
                        size="small"
                        className="tab-input"
                        onValueChanged={(e: EonUiInputCustomEvent<any>) => {
                            setMeetingTopic(e.target.value);
                            setInvalidMeetingTopic(false);
                        }}
                        invalid={invalidMeetingTopic}
                        error-message={t("fieldRequired")}
                        placeholder={t(
                            "titleOfMeetingPlaceholder"
                        )}
                        label={t("titleOfMeetingLabel")}
                        labelOutside
                        value={meetingTopic}
                    />
                )}
            </div>
            <div className="other-details">
                <div className="location">
                    <div className="label">
                        {t("manageBooking.meetingLocation")}
                    </div>
                    <div className="loc">
                        {currentBookingData.meeting_location}
                    </div>
                </div>
                { action === "edit" && (
                    <div className="timing">
                        <div className="date">{formattedMeetingDate}</div>
                        <div className="time">
                            {currentBookingData.meeting_timing}
                        </div>
                        <div className="duration">
                            {t("duration")}:{" "}
                            {getMeetingDuration(
                                currentBookingData.meeting_id
                            )}
                        </div>
                    </div>
                )}
            </div>
            { action === "edit" && (
                <div className="reschedule-to">
                    {t("manageBooking.rescheduleTo")}
                </div>
            )}
            <div className="form">
                <div className="row">
                    <div className="date">
                        <EonUiDatePicker
                            placeholder="datepicker"
                            onValueChanged={(e) => {
                                setDateValue(e.target.value);
                            }}
                            onDatePickerOpened={() => {
                                setPreFillComplete(true);
                                setDateValueChanged(true);
                            }}
                            value={dateValue}
                            name="date"
                            size="small"
                            label={t("date")}
                            labelOutside={true}
                            maxDaysBefore={0}
                            selectToday={true}
                            dateFormat="dd/MM/yyyy"
                            lang={i18n.language}
                            key={i18n.language}
                        />
                    </div>
                    <div className="start-time dropdown">
                        { dateValue && <TimePicker
                            date={dateValue}
                            time={startTime}
                            nearestTime={dateValueChanged ? "" : startTime}
                            onValueChangedCallback={(timeValue: string) =>
                                setStartTime(timeValue)
                            }
                            onDropdownOpenedCallback={() =>
                                setPreFillComplete(true)
                            }
                        /> }
                    </div>
                </div>
                <div className="row">
                    <div className="duration dropdown">
                        <EonUiDropdown
                            placeholder="dropdown"
                            onValueChanged={(e) => {
                                setDuration(e.target.value);
                            }}
                            onDropdownOpened={() =>
                                setPreFillComplete(true)
                            }
                            value={duration}
                            name="dropdown"
                            size="small"
                            label={t("duration")}
                            labelOutside={true}
                        >
                            {DURATION_OPTIONS.map((val, index) => (
                                <EonUiDropdownOption
                                    placeholder="option"
                                    key={`${index.toString()}`}
                                    value={val}
                                    label={t(`durationOptions.${val}`)}
                                />
                            ))}
                        </EonUiDropdown>
                    </div>
                    { (action === "rebook" && currentBookingData.meeting_type === "Meeting Room") && (
                        <div className="participants">
                            <EonUiInput
                                trailingIconName="user_group_alt"
                                type="number"
                                label={`${t("participants")} (${t("max")} ${getMaxCapacity(currentBookingData.meeting_id)})`}
                                labelOutside
                                placeholder="Ex. 3 or 5"
                                value={numberOfParticipantsRebooking}
                                max={getMaxCapacity(currentBookingData.meeting_id)}
                                size="small"
                                invalid={invalidNumberOfParticipants}
                                error-message={t("fieldRequired")}
                                onValueChanged={(e) => {
                                    setNumberOfParticipantsRebooking(e.target.value);
                                    setInvalidNumberOfParticipants(false);
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
            { errorMessage && (
                <div className="err-msg">{errorMessage}</div>
            )}
            <div className="btn">
                <div className="confirm">
                    <EonUiButton
                        text={t("confirm")}
                        disabled={updateSuccess || !!errorMessage || availabilityCode !== 0}
                        onClick={() => {
                            if (action === "edit") {
                                updateBooking(currentBookingData.meeting_id);
                            } else if (action === "rebook") {
                                saveBooking(currentBookingData.meeting_id);
                            }
                        }
                        }
                    ></EonUiButton>
                </div>
                <div className="cancel">
                    <EonUiButton
                        rank="secondary"
                        text={successMessage ? t("close") : t("cancel")}
                        onClick={closePopup}
                    ></EonUiButton>
                </div>
            </div>
        </div>
    );
};

export default EditBooking;
