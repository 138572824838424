import "./TimePicker.scss";
import {
    EonUiDropdown,
    EonUiDropdownOption,
} from "@eon-ui/eon-ui-components-react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getTimeStrings, preselectNearestTime } from "./timeStrings";
import { getTimeInSeconds } from "../../util/date";

type TimePickerProps = {
    date: string;
    time: string;
    nearestTime?: string;
    onValueChangedCallback: (timeValue: string) => void;
    onDropdownOpenedCallback?: () => void;
};

function TimePicker({ date, time, nearestTime, onValueChangedCallback, onDropdownOpenedCallback }: TimePickerProps) {
    const { t } = useTranslation("room-booking");
    const isFutureDate = new Date(date) > new Date();
    const timeStrings = getTimeStrings(date, nearestTime);
    if (!nearestTime) nearestTime = preselectNearestTime(timeStrings, isFutureDate);
    useEffect(() => {
        nearestTime && onValueChangedCallback(nearestTime);
    }, [date]);
    
    return (
        <EonUiDropdown
            id="time-picker"
            data-testid="timePickerRoot"
            placeholder="00:00"
            onValueChanged={(e) => onValueChangedCallback(e.target.value)}
            onDropdownOpened={(e) => {
                onDropdownOpenedCallback && onDropdownOpenedCallback();
                const parentElement = e.target.parentElement;
                if (parentElement) {
                    parentElement.style.zIndex = getTimeInSeconds().toString();
                }
                const dropdown = document.getElementById('time-picker');
                const selectedOption = dropdown?.querySelector('eon-ui-dropdown-option[selected]');
                if (selectedOption) {
                    selectedOption.scrollIntoView({ block: "nearest" });
                }
            }}
            onDropdownClosed={(e) => {
                const parentElement = e.target.parentElement;
                if (parentElement) {
                    parentElement.style.removeProperty('z-index');
                }
            }}
            value={time}
            name="dropdown"
            size="small"
            height="256px"
            label={t("startingTime")}
            labelOutside={true}
        >
            {timeStrings.map((item) => (
                <EonUiDropdownOption key={item} value={item} label={item} />
            ))}
        </EonUiDropdown>
    );
}

export default TimePicker;
