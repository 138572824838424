import { HttpServiceGet } from "./httpService";
import { EnvironmentConfig } from "../config/EnvironmentConfig";
import axios from "axios";

export const clearSystemMemory = async (kId: string) => {
    console.log({ kId });
    const tokenResp = await HttpServiceGet(`${EnvironmentConfig.gatewayURL}/api/nexthink/nexthink-token`);

    if (tokenResp) {

        const cloudPcDataResp = await axios({
            url: `${EnvironmentConfig.gatewayURL}/api/nexthink/device-detail`,
            method: "post",
            data: {
                "queryId": "#last_10_devices_of_user_by_session",
                "nexthinkToken": tokenResp.data.access_token,
                "parameters": {
                    "UserName": `${kId}*`
                }
            }
        });

        const deviceId = cloudPcDataResp.data.data.length ? cloudPcDataResp.data.data[0]["device.collector.uid"] : "aadad66f-d624-4d14-85a1-21abd594aa92";

        return axios({
            url: `${EnvironmentConfig.gatewayURL}/api/nexthink/trigger-remote-action`,
            method: "post",
            data: {
                "remoteActionId": "#disk_cleanup_light",
                "devices": deviceId,
                "expiresInMinutes": 60,
                "triggerInfo": {
                    "externalSource": "ME@EON Integraton",
                    "reason": "Reason-test",
                    "externalReference": "me8eon-action"
                },
                "nexthinkToken": tokenResp.data.access_token
            },
        })
            .then((data) => data.data.requestId)
            .catch((error) => {
                console.error(error);
            });
    } else {
        return null;
    }
};