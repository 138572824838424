import {createContext, ReactNode, useCallback, useEffect, useState} from "react";
import useAiData, {IAiAssistData} from "./hooks/useAiData";
import {Message} from "../ai/ai.types";
import {AiClient, OutputAndSource, useAiWithRag} from "../ai/ai.rag.client";
import { useTranslation } from "react-i18next";
import { ITKeywords } from "./ITKeywords";
import useSearchStore from "../../store/searchStore";
export interface MessageWithActionAndData extends Message {
    action: string;
    data?: any;
    isActionTrigger?: boolean
}

export type QuickActionType = "createIncident" | "chatWithServiceDesk" | "requestCallback" | undefined;

interface IContextValues {
    handleChangeCurrentCardKey: (key: string) => () => void;
    currentCardKey: string;
    handleAiQuery: (query: string, action?: string) => void;
    messages: MessageWithActionAndData[];
    actualMessages: MessageWithActionAndData[];
    isLoading: boolean;
    cards: IAiAssistData;
    handleResetChat: () => void;
    setMessages?: (messages: MessageWithActionAndData[]) => void;
    incidentText: string;
    setIncidentText: (value: string) => void;
    isIncidentTextPending: boolean;
    setIsIncidentTextPending: (value: boolean) => void;
    isITIssue: boolean;
    quickActionType: QuickActionType;
    setQuickActionType: (actionType: QuickActionType) => void;
}

const AiAssistContext = createContext<IContextValues>({
    handleChangeCurrentCardKey: () => () => {
    },
    currentCardKey: "",
    messages: [],
    actualMessages: [],
    handleAiQuery: () => {
    },
    isLoading: false,
    cards: {},
    handleResetChat: () => {
    },
    setMessages: () => {
    },
    incidentText: "",
    setIncidentText: () => {
    },
    isIncidentTextPending: true,
    setIsIncidentTextPending: () => {

    },
    isITIssue: false,
    quickActionType: undefined,
    setQuickActionType: () => {

    }
});

const AiAssistProvider = ({children}: { children: ReactNode }) => {
    const cards: IAiAssistData = useAiData();
    const [messages, setMessages] = useState<MessageWithActionAndData[]>([]);
    const [actualMessages, setActualMessages] = useState<MessageWithActionAndData[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentCardKey, setCurrentCardKey] = useState("selfService");
    const [incidentText, setIncidentText] = useState<string>("");
    const [isIncidentTextPending, setIsIncidentTextPending] = useState(true);
    const [isITIssue, setIsITIssue] = useState<boolean>(false);
    const [quickActionType, setQuickActionType] = useState<QuickActionType>(undefined);
    const { language } = useTranslation().i18n;
    const ai: AiClient = useAiWithRag();
    const { setShowLiveAgentChat } = useSearchStore();

    const handleChangeCurrentCardKey = useCallback((key: string) => () => {
            setCurrentCardKey(key);
        }
        , [setCurrentCardKey]);

    const handleAiQuery = useCallback((query: string, action: string = "user-query") => {
        if(query.trim().length > 0 ) {
            setMessages([...messages, {
                role: "user",
                action,
                content: query,
            }]);
            setIsLoading(true);
            }
        }
        , [cards, currentCardKey, messages]);

    const handleAiAssist = useCallback(async (query: string, action: string) =>{
        const messagesWithoutLast = actualMessages.slice(0, messages.length - 1);
            return await cards[currentCardKey].handleAiQuery(messagesWithoutLast, query, action, quickActionType);
        }
        , [cards, currentCardKey, messages]);

    useEffect(() => {
        if (messages.length === 0) return;
        
        const lastMessage = messages[messages.length - 1];
        const role = lastMessage.role;
        const action = lastMessage.action;

        if (action !== "quick-actions") {
            setActualMessages([...actualMessages, lastMessage]);
        }

        // console.log("last message", lastMessage);

        const getResponse = async () => {
            const query = lastMessage.content;
            const response: OutputAndSource = await handleAiAssist(query, action);

            setMessages([...messages, {
                role: "assistant",
                content: response.output,
                action: response?.action,
                data: response?.data,
            }]);
            setIsLoading(false);
        };
        if (role !== "assistant") {
            getResponse().then(r => r);
        }
    }, [messages]);

    useEffect(() => {
        if (actualMessages.length === 0) return;
        
        const lastMessage = actualMessages[actualMessages.length - 1];
        const role = lastMessage.role;
        
        // console.log("last actual message", lastMessage);

        const getIncidentText = async () => {
            const response = await ai.aiClientSummariseChatHistoryToIncident(
                actualMessages,
                "",
                language
            );
            setIncidentText(response.output);
            setIsIncidentTextPending(false);
        };

        const checkITIssue = () => {
            setIsITIssue(false);
            const query = lastMessage.content;
            for (const keyword of ITKeywords) {
                if (query.toLowerCase().includes(keyword.toLowerCase())) {
                    setIsITIssue(true);
                    break;
                }
            }
        };

        if (role === "assistant") {
            getIncidentText();
        } else {
            checkITIssue();
        }
    }, [actualMessages]);

    const handleResetChat = useCallback(() => {
        setMessages([]);
        setActualMessages([]);
        setShowLiveAgentChat(false);
        setQuickActionType(undefined);
    }, [setMessages, setActualMessages]);

    return (
        <AiAssistContext.Provider
            value={{
                handleChangeCurrentCardKey,
                currentCardKey,
                handleAiQuery,
                messages,
                actualMessages,
                setMessages,
                isLoading,
                cards,
                handleResetChat,
                incidentText,
                setIncidentText,
                isIncidentTextPending,
                setIsIncidentTextPending,
                isITIssue,
                quickActionType,
                setQuickActionType
            }}>
            {children}
        </AiAssistContext.Provider>
    );
};

export {AiAssistContext, AiAssistProvider};