import {DropDownInfo, findDropDown} from "../../domain/info";
import React from "react";
import {iconMap, m365DataSourceFilterNames} from "../../domain/info/m365Info";
import {DataType} from "./ElSearchAdvanceHelpers";

export type FindTabFilterStrategy = (dataType: DataType, searchIndicies: string[]) => TabFilterStrategy

export function isM365(searchIndicies: string[]): boolean {
    const result = searchIndicies.find(si => si.includes('m365')) !== undefined;
    return result;
}

export function defaultFindTabFilterStrategy(dataType: DataType, searchIndicies: string[]): TabFilterStrategy {
    if (dataType === 'm365') {
        const listForFilters: ListForFiltersFn = (): DropDownInfo[] => m365DataSourceFilterNames.map(name => ({
            icon: `${iconMap[name]}`,
            label: name,
            value: name,
        }));
        return {listForFilters, searchIndicies};
    } else {
        const listForFilters: ListForFiltersFn = () => findDropDown();
        const result: TabFilterStrategy = {listForFilters, searchIndicies, showNumbers: true};
        return result;
    }
}

export const FindTabFilterContext = React.createContext<FindTabFilterStrategy>(defaultFindTabFilterStrategy);

export function useTabFilter(dataType: DataType, searchIndicies: string[]) {
    return React.useContext(FindTabFilterContext)(dataType, searchIndicies);
}

export type ListForFiltersFn = () => DropDownInfo[]
export type TabFilterStrategy = {
    listForFilters: ListForFiltersFn
    searchIndicies: string[]
    showNumbers?: boolean

}