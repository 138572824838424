import axios from "axios";
import AzureAiClientBase, {TAzureAiClientConstructor} from "./ai.base.client";
import {Message} from "../ai.types";
import {SearchFn} from "../../../search/search";
import {ESApiFinalResponseInterface} from "../../../interfaces/ElasticSearchInterface";
import {getFrom} from "../../../util/map";
import {OutputAndSource} from "../ai.rag.client";
import {defaultSearchContext} from "../../../search/searchContext";
import {knnSearch} from "../../../search/all.searchs";
import {AiActionByCustomType, AiActionByType} from "../ai.actions";
import {getDecisionTree} from "../../ai-assist-refactored/quick-actions/decisionTree";
import {QuickActionType} from "../../ai-assist-refactored/AiAssist.context";
import aiPromptClassifier from "../actions/ai.promptClassifier";
import getRagPromptWithDocuments from "../prompts/ai.prompts.rag";
import mapSearchResultToDocument from "../actions/ai.mapSearchResultToDocument";
import getRagPromptWithPersonalizedDocuments from "../prompts/ai.prompts.ragPersonalized";

type AiRagConfig = {
    searchFn: SearchFn<ESApiFinalResponseInterface>;
    searchIndicies: string[];
    makeOutputAndSource: (
        searchResultId: string | undefined,
        messages: Message[],
    ) => OutputAndSource;
};

export function defaultAiRagConfig(
    searchIndicies: string[],
    searchFn: SearchFn<ESApiFinalResponseInterface>
): AiRagConfig {
    return {
        searchIndicies,
        searchFn,
        makeOutputAndSource: (
            searchResultId: string | undefined,
            messages: Message[],
            data?: any
        ): OutputAndSource => ({
            output: messages[0].content,
            source: searchResultId || "No search result found",
            action: "rag",
            data,
        }),
    };
}

export interface IAiRagClient {
    config: TAzureAiClientConstructor;
    elasticApiKey?: string;
    searchIndicies?: string[];
}

const personalizedSearchIndiciesByLanguage = {
    "sv": ["semantic-azureblob-swedish-prod"],
    default: []
};

export class AiRagClient extends AzureAiClientBase {
    protected elasticApiKey: string | undefined;
    private readonly searchContext: ReturnType<typeof defaultSearchContext>;
    private aiRagConfig: AiRagConfig;

    constructor({config, elasticApiKey, searchIndicies}: IAiRagClient) {
        super(config);
        this.elasticApiKey = elasticApiKey;
        this.searchContext = defaultSearchContext(axios, this.elasticApiKey);

        this.aiRagConfig = {
            ...defaultAiRagConfig(
                searchIndicies || [],
                knnSearch(this.searchContext)
            ),
        };
    }

    public async aiClientQuickActions(history: Message[], query: string, quickActionType: QuickActionType) {
        const lastMessageContent = history[history.length - 1]?.content;
        const category = lastMessageContent ? "ongoing" : "new";
        const findNode = getDecisionTree().find(node => node.userCommand === query && (!node.condition || node.condition({
            category,
            quickActionType
        })));
        return getFrom(AiActionByCustomType)("quick-actions")(findNode);
    }

    public async aiClientWithRag(history: Message[], query: string, language: string, customSearchIndicies?: string[]) {
        const classificationResult = aiPromptClassifier(query, language);
        const sendWithPreviousHistory = (messages: Message[], otherConfig?: any) => this.sendRequest([...history, ...messages], otherConfig);

        const searchQuery = (indicies: string[]) => this.aiRagConfig.searchFn({
            dataType: "assistance",
            searchTerm: query,
            searchIndexes: indicies
        });

        const classificationMap = {
            "action": async () => {
                const documents = await searchQuery(["actions-prod"]);
                return await getFrom(AiActionByType)(documents.data[0].action)(sendWithPreviousHistory);
            },
            "rag": async () => {
                const searchResults = await searchQuery(customSearchIndicies || this.aiRagConfig.searchIndicies);
                const firstThreeResults = searchResults.data.slice(0, 3);
                const mappedTextFromDocuments = firstThreeResults.map(mapSearchResultToDocument);
                const aiPrompt = getRagPromptWithDocuments(mappedTextFromDocuments, language);
                const message = [{role: "system", content: aiPrompt}, {role: "user", content: query}] as Message[];
                const outputMessages = await sendWithPreviousHistory(message);
                return this.aiRagConfig.makeOutputAndSource("", outputMessages);
            },
            "personalizedRag": async () => {
                const personalizedIndiciesForLanguage = getFrom(personalizedSearchIndiciesByLanguage)(language);
                const searchResults = await searchQuery(customSearchIndicies || this.aiRagConfig.searchIndicies);
                const personalizedIndiciesSearchResults = personalizedIndiciesForLanguage.length > 0 ? await searchQuery(personalizedIndiciesForLanguage) : {data: []};

                const firstThreeSearchResults = searchResults.data.slice(0, 3);
                const firstThreePersonalizedSearchResults = personalizedIndiciesSearchResults.data.slice(0, 3);

                const mappedTextFromDocuments = firstThreeSearchResults.map(mapSearchResultToDocument);
                const mappedTextFromPersonalizedDocuments = firstThreePersonalizedSearchResults.map(mapSearchResultToDocument);

                const aiPrompt = getRagPromptWithPersonalizedDocuments(mappedTextFromDocuments, mappedTextFromPersonalizedDocuments, language);
                const message = [{role: "system", content: aiPrompt}, {role: "user", content: query}] as Message[];
                const outputMessages = await sendWithPreviousHistory(message, {model: "gpt-4o"});
                return this.aiRagConfig.makeOutputAndSource("", outputMessages);
            },
            default: async () => {
                throw new Error(`Classification result is not supported`);
            }
        };

        return getFrom(classificationMap)(classificationResult)();
    }
}