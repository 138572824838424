import {useState} from "react";
import {Diagnostics} from "../../components/diagnostics/diagnostics";
import {AdminButton} from "./adminButton";
import {Environment} from "./environment";
import {AdminLinks, TranslationLinks} from "./admin.links";
import {Feedback} from "./feedback";
import {SpaceDetails} from "./spaceDetails";
import {CheckUser} from "./checkUser";
import {IndexingMetrics, Indicies} from "./reportsAndMetrics";


export type AdminPageType = 'Indicies' | 'Links' | 'Translation' | 'SpaceDetails' | 'Feedback' | 'IndexingMetrics' | 'Environment' | "Diagnostics" | 'Check user';
const adminPageTypes: AdminPageType[] = ['Indicies', 'Links', 'Translation', 'SpaceDetails', 'Feedback', 'IndexingMetrics', 'Environment', "Diagnostics", 'Check user'];

export function AdminPage() {
    const [sel, setSel] = useState<AdminPageType>('Indicies');
    if (localStorage.getItem('admin') !== 'true') return <div>Must be an admin to use this page</div>;
    return (
        <div>
            <h1>Admin Page</h1>
            <ul role='navigation' style={{display: 'flex', gap: '8px', listStyleType: 'none', padding: 0}}>
                {adminPageTypes.map(t =>
                    <li key={t}><AdminButton text={t} selected={sel === t} onClick={() => setSel(t)}/></li>)}
            </ul>
            {sel === 'Indicies' && <Indicies/>}
            {sel === 'IndexingMetrics' && <IndexingMetrics/>}
            {sel === 'SpaceDetails' && <SpaceDetails/>}
            {sel === 'Feedback' && <Feedback/>}
            {sel === 'Environment' && <Environment/>}
            {sel === 'Links' && <AdminLinks/>}
            {sel === 'Translation' && <TranslationLinks/>}
            {sel === 'Diagnostics' && <Diagnostics/>}
            {sel === 'Check user' && <CheckUser/>}
        </div>
    )
        ;
}

