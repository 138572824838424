import {createContext, useContext} from "react";
import {defaultGraphApiTokenFn} from "../../../search/graph.api.search";
import {msalInstance} from "../../../msalConfig";

export type FetchProfileImageResult = {
    imageUrl: string;
    dispose: () => void;

}
export type FetchProfileProps = {
    userId: string,
    token?: () => Promise<string> //This can't be just a string because of time: the token may expire
}
export type FetchProfileImageUrlFn = (props: FetchProfileProps) => Promise<FetchProfileImageResult>;

// const imageDefaultTokenFn = popupGraphApiTokenFn(
const imageDefaultTokenFn = defaultGraphApiTokenFn(
    [`https://graph.microsoft.com/User.ReadBasic.All`],
    msalInstance
);

export const fetchProfileImageUrl: FetchProfileImageUrlFn = async ({userId, token = imageDefaultTokenFn}): Promise<FetchProfileImageResult> => {
    const graphEndpoint = `https://graph.microsoft.com/v1.0/users/${userId}/photo/$value`;
    const response = await fetch(graphEndpoint, {headers: {Authorization: `Bearer ${await token()}`}});
    if (!response.ok)
        throw new Error(`Failed to fetch profile image for user ${userId} at ${graphEndpoint} ${response.statusText} (Status: ${response.status}). Details: ${(await response.text())}`);
    const blob = await response.blob();
    const imageUrl = URL.createObjectURL(blob);
    return {imageUrl, dispose: () => URL.revokeObjectURL(imageUrl)};
};

export const fetchProfileImageContext = createContext(fetchProfileImageUrl);
type ProfileImageProviderProps = {
    value: FetchProfileImageUrlFn
    children: React.ReactNode
};

export function FetchProfileImageUrlProvider({children, value}: ProfileImageProviderProps) {
    return <fetchProfileImageContext.Provider value={value}>{children}</fetchProfileImageContext.Provider>;
}

export function useFetchProfileImage(): FetchProfileImageUrlFn {
    return useContext(fetchProfileImageContext);
}