import { authentication } from "@microsoft/teams-js";
import { AuthenticationResult } from "@azure/msal-browser";
import { JwtPayload, jwtDecode } from "jwt-decode";

//https://learn.microsoft.com/en-us/microsoftteams/platform/tabs/how-to/authentication/tab-sso-code
//https://learn.microsoft.com/en-us/microsoftteams/platform/tabs/how-to/authentication/tab-sso-register-aad
export const authoriseTeamsUser = async (
    ssoLogin: (email: string) => Promise<AuthenticationResult | null>
) => {
    try {
        const access_token = await authentication.getAuthToken();
        const decodedToken: JwtPayload = jwtDecode(access_token);
        const { preferred_username } = decodedToken as any;
        if (preferred_username) {
            await ssoLogin(preferred_username);
            return true;
        }
        return false;
    } catch (err) {
        console.log(err);
        return false;
    }
};
