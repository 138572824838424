import {DataObject, emptyDataObject, SourceInfo} from "../info";
import {getExtension} from "../../util/strings";

const oneDriveParts = ['personal', 'me'];
export const m365Info: SourceInfo = {
    match: "m365",
    dropDown: undefined,
    titleAndTarget: (result: any) => ({
        title: `someTitle ${JSON.stringify(result)}`,
        target: result?.target,
        source: undefined,
    }),
    makeDataObject: (c: any) => {
        const url = c.resource.webUrl;
        const parts = url?.split("/") || [];
        const thirdPart = parts[3];
        const icon = oneDriveParts.includes(thirdPart) ? 'onedrive' : 'sharepoint';
        const result: DataObject = {
            ...emptyDataObject,
            id: c.resource.id,
            icon,
            name: c.resource.name,
            link: `${url}?web=1`,
            createdAt: c.resource.createdDateTime,
            lastUpdate: c.resource.lastModifiedDateTime,
            index: "m365",
            render: "html",
            desc: c.summary,
        };
        return result;
    },
};

export type M365DataSourceFilterName = 'powerpoint' | 'word' | 'excel' | 'pdf' | 'other';
export const m365DataSourceFilterNames: M365DataSourceFilterName[] = ['powerpoint', 'word', 'excel', 'pdf', 'other'];
export const iconMap = {
    powerpoint: 'pptx',
    word: 'docx',
    excel: 'xlsx',
    pdf: 'pdf',
    other: 'genericfile'
};
export const extensionMap: Record<string, M365DataSourceFilterName> = {
    pptx: 'powerpoint',
    ppt: 'powerpoint',
    docx: 'word',
    doc: 'word',
    xlsx: 'excel',
    xls: 'excel',
    pdf: 'pdf'
};
//must be in sync with above
export const firstIndexLookup: Record<string, string> = {
    'powerpoint': '(filetype:ppt OR filetype:pptx)',
    'word': '(filetype:docx OR filetype:doc)',
    'excel': '(filetype:xlsx OR filetype:xls)',
    'pdf': 'filetype:pdf',
    'other': 'NOT (filetype:ppt OR filetype:pptx OR filetype:docx OR filetype:doc OR filetype:xlsx OR filetype:xls OR filetype:pdf)'
};

export function transformDataIntoDataSourceFiltersForM365(data: any[]) {
    const counts: Record<M365DataSourceFilterName, number> = {powerpoint: 0, word: 0, excel: 0, pdf: 0, other: 0};
    data.forEach((d) => {
        const url = d.resource?.webUrl;
        const extension = getExtension(url);
        const dataSourceName: M365DataSourceFilterName = extensionMap[extension] || 'other';
        counts[dataSourceName] = counts[dataSourceName] + 1;
    });
    const result = Object.entries(counts).map(([key, value]) => ({key, doc_count: value}));
    return result;
}