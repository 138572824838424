import React, {useEffect, useState} from "react";
import "@elastic/react-search-ui-views/lib/styles/styles.css";
import "./ElSearchAdvance.scss";
import {EonUiIcon, EonUiLink} from "@eon-ui/eon-ui-components-react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {search, transformSearchResultsToDisplay} from "./ElSearchGetData";
import SearchFiltersTop from "./SearchFiltersTop";
import {DataType, SearchInput} from "./ElSearchAdvanceHelpers";
import {FilterSetters} from "./elsearch.domain";
import LoaderComponent from "../loader-component/LoaderComponent";
import {SearchRequest, useSearchFn} from "../../search/search";
import {FeedbackFn, useFeedback} from "../feedback/feedback";
import {useAuth} from "../../MsalContext";
import SearchFiltersRight from "./SearchFiltersRight";
import {DataSourceFilter, ESApiFinalResponseInterface, MetadataFilters, SelectedMetadataFilters,} from "../../interfaces/ElasticSearchInterface";
import AiAssist from "../ai-assist-refactored/AiAssist";
import {AiAssistProvider} from "../ai-assist-refactored/AiAssist.context";
import AiButton from "../ai-button/AiButton";
import UnifiedSearch from "./unified-search/UnifiedSearch";
import {ExplainControlCenter} from "../diagnostics/explain";
import {useExplainFlag} from "../../hooks/useExplain";
import {useFilterDebugFlag} from "../../hooks/useFilterDebugFlag";
import {FilterDebug} from "./filter-debug/filter.debug";
import {Scroll} from "../scroll";
import {useDebouncedCallback} from "../../hooks/useDebouncedCallback";
import {transformDataIntoDataSourceFiltersForM365} from "../../domain/info/m365Info";
import {useComponents} from "./context/components.context";

function NoData({noMoreData, searchTerm}: { noMoreData?: boolean, searchTerm?: string; }) {
    const {t} = useTranslation();
    return (
        <li className={noMoreData ? "no-more-data" : "no-data"} key={"noData"}>
            {noMoreData ? <></> : <EonUiIcon
                scheme="red500"
                placeholder="search"
                size="normal"
                key={"search"}
                name="search"
            ></EonUiIcon>}
            {t(`filterPage.filterMenu.${noMoreData ? "noMoreData" : "noData"}`)}
            {noMoreData ? '' : <>&nbsp;<strong>{searchTerm}</strong></>}
        </li>
    );
}

export function transformFoundCountsIntoDataFilters(searchIndex: string[], count: DataSourceFilter[]) {
    if (!searchIndex.length) return count;
    return searchIndex.map((index) => {
        const matchingCount = count.find((c) => c.key === index)?.doc_count ?? 0;
        return {key: index, doc_count: matchingCount};
    });
}


export const ElSearchAdvance: React.FC = () => {
    const [dataType, setDataType] = useState<DataType>("all");
    const [searchIndex, setSearchIndex] = useState<string[]>([]);
    const [additionalFilters, setAdditionalFilters] = useState<any>({});
    const [searchTerm, setSearchTermRaw] = useState("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [dataSourceFilters, setDataSourceFiltersRaw] = useState<DataSourceFilter[]>([]);
    const [resultsToDisplay, setResultsToDisplay] = useState<any[]>([]);
    const [filterCount, setFilterCount] = useState<number>(0);
    const [showAiAssist, setShowAiAssist] = useState(false);
    const [metadataFilters, setMetadataFilters] = useState<MetadataFilters>({});
    const [selectedMetadataFilters, setSelectedMetadataFilters] = useState<SelectedMetadataFilters>({});
    const {t} = useTranslation();
    const {isLimitedAccess} = useAuth();
    const searchFn = useSearchFn();
    const feedbackFn: FeedbackFn = useFeedback();
    const [noMoreData, setNoMoreData] = useState(false);
    const {ElSearchAdvanceCard} = useComponents(dataType);

    function setSearchTerm(searchTerm: string) {
        setSearchTermRaw(searchTerm);
        setSelectedMetadataFilters({});
        setAdditionalFilters({});
    }

    useEffect(() => {//Update the search term from the URL on page load
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const searchQuery = urlParams.get("q");
        if (searchQuery) setSearchTerm(searchQuery);
        //     if (!isQaPocUser)
    }, []);

    const setters: FilterSetters = {
        setIsLoading,
        setResultsToDisplay,
        setDataSourceFilters: setDataSourceFiltersFromFound,
        setDataType,
        setSearchIndex,
        setAdditionalFilters,
        setMetadataFilters,
        setFilterCount,
    };

    const rawSearch = search({
        setIsLoading, setResultsToDisplay, searchTerm, resultsToDisplay, searchFn, setNoMoreData, transformSearchResultsToDisplay,
        query: (append: boolean, searchTerm: string, baseData: any[]): SearchRequest => ({
            append,
            dataType,
            searchTerm,
            searchIndexes: searchIndex,
            additionalFilters,
            ...(baseData.length && {
                searchAfter: [
                    baseData[baseData.length - 1].score,
                    baseData[baseData.length - 1].id,
                ],
            }),
            expectedRecordSize: 20,
        }),
        setDataSourceFiltersWrapper: setDataSourceFiltersFromFound,
        setMetadataFilters: setMetadataFiltersWrapper,
        feedback: (searchTerm) => feedbackFn({source: "query", page: "advanced", query: searchTerm,}),
        atEnd: () => { if (searchIndex.length) setSelectedMetadataFilters(metadataFilters[searchIndex[0]]); }
    });
    const debouncedSearch = useDebouncedCallback(rawSearch, 500);


    useEffect(() => {
        rawSearch(false); //no need to debounce as searchTerm is already debounced
    }, [searchTerm, searchIndex, additionalFilters]);


    useEffect(() => {
        if (dataType === "assistance") {
            setShowAiAssist(true);
        }
    }, [dataType]);


    function setDataSourceFiltersFromFound(data: ESApiFinalResponseInterface[], count: DataSourceFilter[]) {
        if (dataType === 'm365')
            return setDataSourceFiltersRaw(transformDataIntoDataSourceFiltersForM365(data));
        setDataSourceFiltersRaw(transformFoundCountsIntoDataFilters(searchIndex, count));
    }

    function setMetadataFiltersWrapper(filters: MetadataFilters) {
        try {
            for (const key in additionalFilters.selectedMetadata) {
                if (!additionalFilters.selectedMetadata[key].length) continue;
                filters[searchIndex[0]][key] = {
                    ...metadataFilters[searchIndex[0]][key],
                };
            }
        } catch (e) {
            console.error(e, filters, metadataFilters);
        }
        setMetadataFilters(filters);
    }

    async function onScroll() {
        if (!noMoreData) return debouncedSearch(true);
    }

    return showAiAssist ? (
        <AiAssistProvider>
            <AiAssist
                closeAiAssist={() => {
                    setShowAiAssist(false);
                    setDataType("all");
                }}
                initialPrompt={searchTerm}
            />
        </AiAssistProvider>
    ) : (
        <Scroll className="search-custom-filter-wrapper" onScroll={onScroll}>
            <Link to="/tile">
                <EonUiLink
                    className="back-button"
                    icon="chevron_small_left"
                    iconPosition="left"
                    placeholder="back"
                    href="javascript:void(0)"
                    text={t("filterPage.filterMenu.backBtn")}
                    title={t("filterPage.filterMenu.backBtn")}
                ></EonUiLink>
            </Link>
            {useExplainFlag() && <ExplainControlCenter query={searchTerm} index={searchIndex[0]}/>}
            {useFilterDebugFlag() && <FilterDebug setters={setters}
                                                  dataType={dataType}
                                                  searchIndex={searchIndex}
                                                  additionalFilters={additionalFilters}
                                                  metadataFilters={metadataFilters}
                                                  selectedMetadataFilters={selectedMetadataFilters}
                                                  dataSourceFilters={dataSourceFilters}
                                                  filterCount={filterCount}/>}
            <div className="search-input">
                <SearchInput
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                />
                {!isLimitedAccess && (
                    <AiButton
                        title={t("chatBot.chatBotTitle")}
                        aiToggle={() => setShowAiAssist(true)}
                    />
                )}
            </div>
            <LoaderComponent isLoading={isLoading} isLinear/>
            <SearchFiltersTop setSearchIndex={setSearchIndex} dataType={dataType} setDataType={setDataType}/>
            {((dataType === "all" && filterCount < 1) ||
                dataType === "assistance") && (
                <UnifiedSearch

                    searchTerm={searchTerm}
                    setters={setters}
                    setShowAiAssist={setShowAiAssist}
                />
            )}
            <div className="custom-result">
                {dataType !== "apps" && dataType !== 'people' && searchTerm && (
                    <SearchFiltersRight
                        dataType={dataType}
                        setters={setters}
                        searchIndex={searchIndex}
                        additionalFilters={additionalFilters}
                        selectedMetadataFilters={selectedMetadataFilters}
                        dataSourceFilters={dataSourceFilters}
                        filterCount={filterCount}
                    />
                )}
                {dataType !== "assistance" && searchTerm && (
                    <ul className="custom-result-container">
                        {resultsToDisplay?.map((result, key) => {
                            return (
                                <ElSearchAdvanceCard
                                    result={result}
                                    key={`${key.toString()}`}
                                    searchTerm={searchTerm}
                                    position={key + 1}
                                />
                            );
                        })}
                        {noMoreData && <NoData noMoreData={resultsToDisplay.length > 0} searchTerm={searchTerm}/>}
                    </ul>
                )}
            </div>
            {isLoading && (
                <LoaderComponent isLoading={isLoading} isLinear/>
            )}
        </Scroll>
    );
};
