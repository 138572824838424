import { EonUiLink } from "@eon-ui/eon-ui-components-react";
import "./QuickActionLinks.scss";
import { useTranslation } from "react-i18next";
import SVGIcon from "../svg-icon/SVGIcon";
import { useContext, useEffect, useState } from "react";
import { useWindowProperties } from "../../context/WindowContext";
import { AiAssistContext, QuickActionType } from "./AiAssist.context";

interface QuickActionLinksProps {
    onItemClick?: (item: string) => void;
}

interface ActionItemList {
    type: QuickActionType;
    icon: string;
    disabled?: boolean;
}

const QuickActionLinks = ({
    onItemClick,
}: QuickActionLinksProps) => {

    const { t } = useTranslation();

    const [showQuickLinks, setShowQuickLinks] = useState<boolean>(true);
    const { isMobile } = useWindowProperties();
    const actionItemsList: ActionItemList[] = [
        { type: "createIncident", icon: "alert" },
        { type: "chatWithServiceDesk", icon: "messenger_01" },
        // { type: "requestCallback", icon: "talk_to_us" }
    ];

    const { isITIssue, handleAiQuery, setQuickActionType } = useContext(AiAssistContext);

    const toggleQuickActions = () => {
        setShowQuickLinks(!showQuickLinks);
    };

    useEffect(() => {
        setShowQuickLinks(!isMobile);
    }, [isMobile]);

    const handleClick = (item: QuickActionType) => {
        console.log("Clicked item:", item, isITIssue);
        const query = t(`chatBot.quickActions.actions.${item}`);
        setQuickActionType(item);
        handleAiQuery(query, "quick-actions");
        isMobile && toggleQuickActions();
        if (onItemClick) {
            onItemClick(query);
        }
    };

    return (
        <div className="ai-assist-quick-action">
            <div className="heading" onClick={isMobile ? toggleQuickActions : undefined}>
                <SVGIcon className="icon" url={'./icons/quick_actions.svg'} />
                <span>{t("chatBot.quickActions.heading")}</span>
                <EonUiLink
                    className="arrow-icon"
                    icon={showQuickLinks ? "chevron_bold_up" : "chevron_bold_down"}
                    iconPosition="right"
                    text=""
                    size="small"
                ></EonUiLink>
            </div>
            <div className="quick-links-wrapper" style={{ display: showQuickLinks ? 'flex' : 'none' }}>
                {actionItemsList.map((item, index) => (
                    <EonUiLink
                        key={index}
                        className="item"
                        onClick={() => !item.disabled && handleClick(item.type)}
                        icon={item.icon}
                        iconPosition="left"
                        text={t(`chatBot.quickActions.actions.${item.type}`)}
                        size="small"
                    ></EonUiLink>
                ))}
            </div>
        </div>
    );
};

export default QuickActionLinks;
