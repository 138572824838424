import axios from "axios";
import {EnvironmentConfig} from "../config/EnvironmentConfig";

export const HttpServicePost = async () => {
    const client_id =
        EnvironmentConfig.snowClientId || "5ab0593c7a9444cb845e8b13f7610baf";
    const baseURL = `${EnvironmentConfig.gatewayURL}/proxy/oauth-api/v1/token`;
    return axios({
        url: baseURL,
        method: "post",
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
            "Content-Type": "application/x-www-form-urlencoded",
            "x-proxy": EnvironmentConfig.snowProxy
        },
        data: {
            client_id,
            scope: "Eon.ServiceManagement.Catalog",
            grant_type: "CLIENT_CREDENTIALS",
        },
    })
        .then((data) => {
            localStorage.setItem("snowBearerToken", data.data.access_token);
        })
        .catch((error) => {
            console.error(error);
        });
};

export const HttpServicePostLiveAgentChat = async () => {
    const client_id =
        EnvironmentConfig.snowClientIdLiveAgentChat || "65cd92c89b65465884bc3d0395ecef55";
    const baseURL = `${EnvironmentConfig.gatewayURL}/proxy/oauth-api/v1/token`;
    let expiresIn = 900;
    const tokenExpiryBuffer = 60;
    return axios({
        url: baseURL,
        method: "post",
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
            "Content-Type": "application/x-www-form-urlencoded",
            "x-proxy": EnvironmentConfig.snowProxyLiveAgentChat
        },
        data: {
            client_id,
            scope: "Eon.Chatbot.Snow",
            grant_type: "CLIENT_CREDENTIALS",
        },
    })
        .then((data) => {
            localStorage.setItem("snowBearerTokenLiveAgentChat", data.data.access_token);
            expiresIn = data.data.expires_in;
        })
        .catch((error) => {
            console.error(error);
        }).finally(() => {
            setTimeout(() => {
                HttpServicePostLiveAgentChat();
            }, (expiresIn - tokenExpiryBuffer) * 1000);
        });
};

export const HttpServiceGet = async (URL, header) => {
    const requestObject = {
        url: URL,
        method: "GET",
        headers: header,
    };
    return await axios(requestObject)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log(error);
        });
};
