import React, { useContext, useEffect, useState } from "react";
import { AiAssistContext, MessageWithActionAndData } from "../AiAssist.context";
import { ReactComponent as AiIcon } from "../../../assets/image/ai-icon32.svg";
import { EonUiTagPill, EonUiTagPillGroup } from "@eon-ui/eon-ui-components-react";
import TypingEffect from "../utils/typing-effect/TypingEffect";
import { DecisionNode, getUserCommand } from "./decisionTree";
import useSearchStore from "../../../store/searchStore";
import { CreateIncidentInput, CreateIncidentResponse } from "../../feedback/feedback.createIncident.component";
import { raiseIncident } from "../../../services/raiseIncident";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

const Options: React.FC<{data: any}> = ({
    data
}) => {
    const [disabled, setDisabled] = useState<boolean>(false);
    const { handleAiQuery } = useContext(AiAssistContext);
    return (
        <div className="bot-content">
            <EonUiTagPillGroup>
                { data?.options?.map(((option: any, index: number) => (
                    <EonUiTagPill
                        key={index}
                        scheme={option.scheme}
                        text={option.label}
                        icon={option.icon}
                        onClick={() => {
                            if (disabled) return;
                            handleAiQuery(getUserCommand(option.nextNodeId), "quick-actions");
                            setDisabled(true);
                        }}
                        className="citation-item"
                        isDisabled={disabled}
                    ></EonUiTagPill>
                ))) }
            </EonUiTagPillGroup>
        </div>
    );
};

const CreateIncident: React.FC<{data: any}> = ({
    data
}) => {
    const [disabled, setDisabled] = useState<boolean>(false);
    const { actualMessages, handleAiQuery, isITIssue, quickActionType } = useContext(AiAssistContext);
    const lastMessageContent = actualMessages.filter(item => item.action === "user-query")?.pop()?.content;
    return (
        <>
            { quickActionType && !isITIssue && <div className="non-it-warning">
                <div>Assistance did not find keywords to identify your issue as an IT-related issue.</div>
                <div>Do you want to create an incident with the Service Desk based on your conversation about <span className="question">&quot;{lastMessageContent}&quot;</span></div>
                <div><span className="recommendation">Recommendation: </span><span className="recommendation-text">if you have an issue which is not related to software or hardware you can try to rephrase your search or ask a follow-up question below.</span></div>
            </div> }
            <CreateIncidentInput isEditIncident={true} />
            <div className="bot-content">
                <EonUiTagPillGroup>
                    { data?.options?.map(((option: any, index: number) => (
                        <EonUiTagPill
                            key={index}
                            scheme={option.scheme}
                            text={option.label}
                            icon={option.icon}
                            onClick={() => {
                                if (disabled) return;
                                handleAiQuery(getUserCommand(option.nextNodeId), "quick-actions");
                                setDisabled(true);
                            }}
                            className="citation-item"
                            isDisabled={disabled}
                        ></EonUiTagPill>
                    ))) }
                </EonUiTagPillGroup>
            </div>
        </>
    );
};

const CreateIncidentResult: React.FC = () => {
    const { t } = useTranslation();
    const [incidentResponse, setIncidentResponse] = useState<any>();
    const [respLoading, setRespLoading] = useState(false);
    const { actualMessages, incidentText } = useContext(AiAssistContext);
    const lastMessageContent = actualMessages.filter(item => item.action === "user-query")?.pop()?.content;

    useEffect(() => {
        const createIncidentApi = async () => {
            setRespLoading(true);
            try {
                const incidentResponse = await raiseIncident(incidentText);
                setIncidentResponse(incidentResponse);
            } catch (e) {
                console.error("Something went wrong. Please try again later.", e);
            } finally {
                setRespLoading(false);
            }
        };
        createIncidentApi();
    }, []);

    return (
        <div className="ticket-container">
            <div className="ticket-desc">{t("chatBot.quickActions.state.ticketDescription")}</div>
            <div className="last-message">
                {lastMessageContent}
            </div>
            <CreateIncidentResponse
                incidentResponse={incidentResponse}
                confirmTicketCreation={true}
                respLoading={respLoading}
            />
        </div>
    );
};

const ChatWithServiceDesk: React.FC<{data: any}> = ({
    data
}) => {
    const [disabled, setDisabled] = useState<boolean>(false);
    const { handleAiQuery } = useContext(AiAssistContext);
    const { setShowLiveAgentChat } = useSearchStore();

    return (
        <>
            <CreateIncidentInput isEditIncident={true} />
            <div className="bot-content">
                <EonUiTagPillGroup>
                    { data?.options?.map(((option: any, index: number) => (
                        <EonUiTagPill
                            key={index}
                            scheme={option.scheme}
                            text={option.label}
                            icon={option.icon}
                            onClick={() => {
                                if (disabled) return;
                                if (option.label === t("chatBot.quickActions.state.connectWithServiceDeskLabel")) {
                                    setShowLiveAgentChat(true);
                                } else {
                                    handleAiQuery(getUserCommand(option.nextNodeId), "quick-actions");
                                }
                                setDisabled(true);
                            }}
                            className="citation-item"
                            isDisabled={disabled}
                        ></EonUiTagPill>
                    ))) }
                </EonUiTagPillGroup>
            </div>
        </>
    );
};

export const quickActions: React.FC<MessageWithActionAndData> = ({
    data,
    content,
}) => {
    const renderStep = (data: DecisionNode): any => {
        switch (data.type) {
            case "options":
                return <Options data={data} />;
            case "create-incident":
                return <CreateIncident data={data} />;
            case "chat-with-service-desk":
                return <ChatWithServiceDesk data={data} />;
            case "create-incident-response":
                return <CreateIncidentResult />;
            case "text":
            default:
                return <></>;
        }
    };

    return (
        <div className="bot-header">
            <div className="eon-logo">
                <AiIcon />
            </div>
            <div className="bot-reply-container">
                <TypingEffect className="bot-text" text={content} speed={1} />
                {renderStep(data)}
            </div>
        </div>
    );
};