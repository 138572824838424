import {EonUiIcon, EonUiLink} from "@eon-ui/eon-ui-components-react";
import {t} from "i18next";
import {useContext} from "react";
import {AiAssistContext} from "../AiAssist.context";
import { useWindowProperties } from "../../../context/WindowContext";

interface IAiHeaderProps {
    closeAiAssist: () =>void;
}

const AiHeader = ({closeAiAssist}: IAiHeaderProps) => {

    const { handleResetChat } = useContext(AiAssistContext);
    const  { isScrollY } = useWindowProperties();

    return (
        <div className="search-wrapper">
            <EonUiLink
                className="back-button"
                iconPosition="left"
                placeholder="back"
                icon="chevron_small_left"
                onClick={() => closeAiAssist()}
                text={t("chatBot.searchBack")}
                title={t("chatBot.searchBack")}
                href="javascript:void(0)"
                size={ isScrollY ? "small" : "normal" }
            ></EonUiLink>
            { <button
                className="new-ai-chat"
                onClick={() => {
                    handleResetChat();
                }}
                title={t("chatBot.newChat")}
            >
                <span className="icon-wrapper">
                    <EonUiIcon
                        placeholder="add"
                        className="margin-add eon-ui-svg-icon"
                        size={ isScrollY ? "tiny" : "small" }
                        name={"add"}
                        scheme="red500"
                    ></EonUiIcon>
                </span>
                <span className="add-new-ai-chat" style={{ fontSize: isScrollY ? "14px" : "18px" }}>{t("chatBot.newChat")}</span>
            </button> }
        </div>
    );
};

export default AiHeader;