import {CSSProperties, useEffect} from "react";

export type ScrollProps = {
    style?: CSSProperties;
    className?: string;
    children: React.ReactNode;
    onScroll: () => Promise<void>;
}


export function Scroll({children, onScroll, style, className}: ScrollProps) {
    useEffect(() => {
        const handleScroll = () => {
            const scrolled = window.innerHeight + window.scrollY + 300 >= document.body.offsetHeight;
            if (scrolled) onScroll();
        };
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [onScroll]);
    return <div style={style} className={className}>{children}</div>;
}