import {useEffect, useState} from "react";
import {AdminTable} from "./admin.table";
import {EnvironmentConfig} from "../../config/EnvironmentConfig";
import axios from "axios";

export type IndexesReport = {
    url: string
    index: string
    description: string
    count: number;
    vectorised: number;
    questionator: number;
    size: number;
}
export type Report = {
    metrics: Record<string, any>,
    indexes: Record<string, IndexesReport>
}

export function useReportIndexes(): () => Promise<Report> {
    return async () => {
        const url = `${EnvironmentConfig.elasticSearchBaseUrl}/report-prod/_search`;
        const body = {
            "size": 0,  // Set to 0 since we only want aggregation results
            "aggs": {
                "latest_per_type": {
                    "terms": {
                        "field": "type.keyword",
                        "size": 10  // Adjust this based on the number of unique types you expect
                    },
                    "aggs": {
                        "latest_document": {
                            "top_hits": {
                                "size": 1,
                                "sort": [
                                    {
                                        "@timestamp": {
                                            "order": "desc"
                                        }
                                    }
                                ]
                            }
                        }
                    }
                }
            }
        };
        const headers = {
            "Content-Type": "application/json",
            Authorization: `ApiKey ${localStorage.getItem("dls-apikey")}`,
        };
        const response = await axios.post(url, body, {headers,});
        const buckets = response.data?.aggregations?.latest_per_type?.buckets;
        const sources = (buckets || []).map((b: any) => b.latest_document.hits.hits[0]._source);
        const indexReport = sources.find((s: any) => s.type === 'indexReport') || {};
        const metrics = sources.find((s: any) => s.type === 'metrics') || {};
        console.log('latest', indexReport, metrics);

        function extract(json: any) {
            return json ? JSON.parse(json) : {};
        }

        const result: Report = {metrics: extract(metrics.metrics), indexes: extract(indexReport.report)};
        console.log('result', result);
        return result;
    };
}

export function Indicies() {
    const indexesReport = useReportIndexes();
    const [report, setReport] = useState<Report>({indexes: {}, metrics: {}});
    useEffect(() => {
        indexesReport().then(r => setReport(r));
    }, []);
    const sorted: IndexesReport[] = Object.values(report.indexes).sort((a, b) => a.index.localeCompare(b.index));
    return <AdminTable
        data={sorted}
        renderCell={{
            'Index': (index: IndexesReport) => index.index,
            'Description': (index: IndexesReport) => index.description,
            'URL': (index: IndexesReport) => index.url,
            'Count': (index: IndexesReport) => index.count?.toLocaleString() || '0',
            'Size': (index: IndexesReport) => index.size?.toLocaleString() || '0',
            'Knn': (index: IndexesReport) => index.vectorised?.toLocaleString() || '0',
            'Q&A': (index: IndexesReport) => index.questionator?.toLocaleString() || '0',
        }} rightJustified={['Count', 'Size']}/>;
}

export function IndexingMetrics() {
    const indexesReport = useReportIndexes();
    const [report, setReport] = useState<Report>({indexes: {}, metrics: {}});
    useEffect(() => {
        indexesReport().then(r => setReport(r));
    }, []);
    return <pre>{JSON.stringify(report.metrics, null, 2)}</pre>;
}