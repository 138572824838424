import React, {Suspense} from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import {EonUiPreloaderSquares} from "@eon-ui/eon-ui-components-react";
import {SearchFnProvider} from "./search/search";
import {m365OrJiraOrAllSearch,} from "./search/all.searchs";
import {defaultSearchContext} from "./search/searchContext";
import axios from "axios";
import {defaultFeedbackFn, FeedbackProvider,} from "./components/feedback/feedback";
import {Diagnostics} from "./components/diagnostics/diagnostics";
import {MsalProvider} from "./MsalContext";
import {AiRagProvider,} from "./components/ai/ai.rag.client";
import {defaultGraphApiTokenFn} from "./search/graph.api.search";
import {msalInstance} from "./msalConfig";
import {defaultJiraTokenFn} from "./search/jql.search";
import {ExplainProvider} from "./components/diagnostics/explain.context";
import {WindowProvider} from "./context/WindowContext";
import {defaultEmailToHash, defaultUniqueUsersFn, UniqueUsersFn, UniqueUsersProvider} from "./components/unique-users/unique.users";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

//it would be great to get this from the Environmental config
//and also setup the right indicies

export const searchContext = defaultSearchContext(axios);
const diagnostics = window.location.href.includes("diagnostics");

const graphApiTokenFn = defaultGraphApiTokenFn(
    [`https://graph.microsoft.com/Sites.Read.All`],
    msalInstance
);
const searchFn = m365OrJiraOrAllSearch(
    searchContext,
    graphApiTokenFn,
    defaultJiraTokenFn([`https://jira.eon.com/.default`], msalInstance)
);
const uniqueUsersFn: UniqueUsersFn = defaultUniqueUsersFn(axios, "unique-users", () => new Date(), defaultEmailToHash);

root.render(
    <React.StrictMode>
        <MsalProvider>
            <WindowProvider>
                <UniqueUsersProvider uniqueUsers={uniqueUsersFn}>
                    <ExplainProvider>
                        <FeedbackProvider feedbackFn={defaultFeedbackFn(axios, "feedback")}>
                            <SearchFnProvider searchFn={searchFn}>
                                <AiRagProvider>
                                    <Suspense
                                        fallback={
                                            <EonUiPreloaderSquares
                                                placeholder="preloader"
                                                className="pre-loader-gif"
                                            ></EonUiPreloaderSquares>
                                        }
                                    >
                                        <App/>
                                        {diagnostics && <Diagnostics/>}
                                    </Suspense>
                                </AiRagProvider>
                            </SearchFnProvider>
                        </FeedbackProvider>
                    </ExplainProvider>
                </UniqueUsersProvider>
            </WindowProvider>
        </MsalProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
