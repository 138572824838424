import {getFrom} from "../../../util/map";

const ragTypeByLanguage = {
    "sv": "personalizedRag",
    default: "rag"
};

const aiPromptClassifier = (prompt: string, language: string) => {
    if (prompt.toLowerCase().includes("show")) {
        if (prompt.toLowerCase().includes("incidents") || prompt.toLowerCase().includes("orders")) {
            return "action";
        }
    }
    return getFrom(ragTypeByLanguage)(language);
};

export default aiPromptClassifier;