import { useEffect, useState } from "react";
import "./App.scss";
import {
    BrowserRouter as Router,
    Navigate,
    Outlet,
    Route,
    Routes,
} from "react-router-dom";
import Header from "./components/header/Header";
import Footer from "./components/footer/footer";
import SearchResultPage from "./pages/search-page/searchPage";
import RoomBooking from "./pages/room-booking/RoomBooking";
import InfoBanner from "./components/info-banner/InfoBanner";
import ServiceRequestPage from "./pages/service-request/ServiceRequest";
import CustomRedirect from "./components/custom-redirect/CustomRedirect";
import TilePage from "./pages/tile-page/TilePage";
import AboutUs from "./pages/about-us/AboutUs";
import { EonUiSection } from "@eon-ui/eon-ui-components-react";
import LoginPage from "./pages/login/LoginPage";
import {
    HttpServicePost,
    HttpServicePostLiveAgentChat,
} from "./services/httpService";
import fetchAndStoreDLSApiKey from "./services/fetchAndStoreDLSApiKey";
import { useAuth } from "./MsalContext";
import { AdminPage } from "./pages/admin-page/admin.page";
import {
    UniqueUserDetails,
    useUniqueUsers,
} from "./components/unique-users/unique.users";
import { initializeQualtrics } from "./util/qualtrics";


function ProtectedRoutes() {
    const { account } = useAuth();
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        if (account !== null) {
            setIsLoading(false);
        }
    }, [account]);
    if (isLoading) return <div>Loading...</div>;
    return account ? <Outlet /> : <Navigate to="/" />;
}



function App() {
    const { account, orgName } = useAuth();
    const uniqueUsers = useUniqueUsers();

    useEffect(() => {
        if (account) {
            fetchAndStoreDLSApiKey(account);
            HttpServicePost();
            HttpServicePostLiveAgentChat();
            uniqueUsers(
                account.username,
                account.idTokenClaims as UniqueUserDetails
            );
            initializeQualtrics();
        }
    }, [account]);

    return (
        <>
            <div className={`wrapper org-scheme org-scheme-${orgName}`}>
                <Header orgName={orgName} />
                <div className="content">
                    <Router>
                        <EonUiSection removeTopSpacing={true}>
                            <Routes>
                                <Route path="/" element={<LoginPage />} />
                                <Route element={<ProtectedRoutes />}>
                                    <Route
                                        path="/tile"
                                        element={<TilePage />}
                                    />
                                    <Route
                                        path="/admin"
                                        element={<AdminPage />}
                                    />
                                    <Route
                                        path="/search-result"
                                        element={<SearchResultPage />}
                                    />
                                    <Route
                                        path="/roombooking"
                                        element={<RoomBooking />}
                                    />
                                    <Route
                                        path="/my-approvals"
                                        element={
                                            <InfoBanner
                                                multiLangHeading="underDevelopmentMsg"
                                                icon="alert"
                                            />
                                        }
                                    />
                                    <Route
                                        path="/orders"
                                        element={
                                            <ServiceRequestPage type="orders" />
                                        }
                                    />
                                    <Route
                                        path="/incidents"
                                        element={
                                            <ServiceRequestPage type="incidents" />
                                        }
                                    />
                                    <Route
                                        path="/it-resources"
                                        element={
                                            <ServiceRequestPage type="itResources" />
                                        }
                                    />
                                    <Route
                                        path="/about-me8eon"
                                        element={<AboutUs />}
                                    />
                                </Route>
                                <Route
                                    path="/api/gingco/code"
                                    element={
                                        <CustomRedirect page="roombooking" />
                                    }
                                />
                                <Route
                                    path="/authorize/sapias"
                                    element={<CustomRedirect page="tile" />}
                                />
                                <Route path="*" element={<LoginPage />} />
                            </Routes>
                        </EonUiSection>
                    </Router>
                </div>
            </div>
            <div className={`org-scheme org-scheme-${orgName}`}>
                <Footer />
            </div>
        </>
    );
}

export default App;
