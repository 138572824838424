import {findSourceInfo} from "../../domain/info";
import {TileDataInterface} from "../../interfaces/TileInterface";
import {withLoading} from "../../util/common-util";
import {SearchFnResult} from "../../search/search";
import {DataSourceFilter, MetadataFilters} from "../../interfaces/ElasticSearchInterface";

export type HasTitleAndIndex = {
    title: string;
    index: string;
};

export function transformSearchResultsToDisplay<T extends HasTitleAndIndex>(raw: T[]): (T | TileDataInterface)[] {
    return raw.flatMap<T | TileDataInterface>(
        (dataObj: T) => {
            const source = findSourceInfo(dataObj.index);
            if (source.restrictPdfTitles && dataObj.title && dataObj.title.includes(".pdf")) return [];
            return [dataObj];
        }
    );
}

export type SearchProps<Query, Raw, Res> = {
    setIsLoading: (value: boolean) => void;
    feedback: (searchTerm: string) => any;
    searchTerm: string,
    resultsToDisplay: Res[],
    query: (append: boolean, searchTerm: string, baseData: Res[]) => Query,
    searchFn: (query: Query) => Promise<SearchFnResult<Raw>>,
    transformSearchResultsToDisplay: (raw: Raw[]) => Res[],
    setResultsToDisplay: (value: any) => void;
    atEnd: () => void;
    setNoMoreData: (value: boolean) => void;
    setDataSourceFiltersWrapper: (data: Res[], count: DataSourceFilter[]) => void;
    setMetadataFilters: (value: MetadataFilters) => void;
}

/* The search process is a pipeline. We go from the searchterm + baseData to the query to the raw results and finally to the display results.
Along the way we have side effects like setting the loading state, setting the results to display, and providing feedback.
This is all done here rather than in the component to keep the component clean and focused on rendering, and also to make the search process testable. */
export const search = <Query, Raw, Res>(
    {
        setIsLoading,
        setResultsToDisplay,
        searchFn,
        feedback,
        transformSearchResultsToDisplay,
        query,
        resultsToDisplay,
        searchTerm,
        atEnd,
        setNoMoreData,
        setDataSourceFiltersWrapper,
        setMetadataFilters
    }: SearchProps<Query, Raw, Res>
) => async (append: boolean) => {
    if (searchTerm === "") return setResultsToDisplay([]);
    // console.log('In the search function', 'searchTerm', searchTerm, 'results', resultsToDisplay, 'append', append);
    if (!append) setNoMoreData(false);
    feedback(searchTerm);
    const constructedQuery = query(append, searchTerm, append ? resultsToDisplay : []);
    const searchRes = await withLoading(setIsLoading,
        async () => {
            const result = await searchFn(constructedQuery);
            return result;
        });
    const dataToDisplay = transformSearchResultsToDisplay(searchRes.data);
    const fullData: Res[] = append ? [...resultsToDisplay, ...dataToDisplay] : dataToDisplay;
    if (searchRes.data.length === 0)
        setNoMoreData(true);
    setDataSourceFiltersWrapper && setDataSourceFiltersWrapper(fullData, searchRes.dataSourceFilters);
    setMetadataFilters && setMetadataFilters(searchRes.metadataFilters);

    setResultsToDisplay(fullData);
    atEnd();
};
